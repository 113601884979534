<template>
  <div>
    <v-data-table :headers="headers" :items="data" hide-default-footer :loading="isLoading('get:operadoras')">
    </v-data-table>
  </div>
</template>

<script>
import UtilsFunc from '@/service/utilsFunc';
import api from '../../../../http';

export default {
  name: "ProcedureFormEditCopyItemsExpand",
  props: {
    operadora: Number,
    pacote: Number
  },
  data: () => UtilsFunc.withCRUDUtils({
    selectedRows: [],
    isSubmitting: false,
    expanded: [],
    data: [],
    headers: [
      { text: "ID", value: "id" },
      { text: "Tipo", value: "tipo" },
      { text: "Item", value: "nome" },
      { text: "Custo", value: "custo" },
      { text: "Ilimitado", value: "ilimitado" },
      { text: "Quantidade", value: "quantidade" },
      { text: "Inicio vigência", value: "inicio_vigencia" },
      { text: "Fim Vigência", value: "fim_vigencia" },
    ]
  }),
  methods: {
    async getPackageItens() {
      const LOADING = 'get:operadoras'
      try {
        this.setLoading(LOADING)
        const { data } = await api.get(`/operadoras/operadora-itempacotes/?operadora=${this.operadora}&pacote=${this.pacote}`)
        this.data = data
          .filter(item => item.ativo)
          .map(item => ({
            ...item,
            tipo: this.getItemType(item),
            nome: this.getItemName(item),
            custo: this.getItemPreco(item),
            ilimitado: item.ilimitado ? 'Sim' : 'Não'
          }))
      } catch (error) {
        console.log(error)
      } finally {
        this.setLoading(LOADING, true)
      }
    },
    getItemType(pacote) {
      if (pacote.taxa) return 'Taxa';
      if (pacote.equipamento) return 'Equipamento';
      if (pacote.procedimento) return 'Procedimento';
      return '';
    },
    getItemName(pacote) {
      if (pacote.taxa) return pacote.taxa.taxa.nome;
      if (pacote.equipamento) return pacote.equipamento.equipamento.nome;
      if (pacote.procedimento) return pacote.procedimento.procedimento.nome;
      return '';
    },
    getItemPreco(pacote) {
      if (pacote.taxa) return pacote.taxa.preco;
      if (pacote.equipamento) return pacote.equipamento.preco;
      if (pacote.procedimento) return pacote.procedimento.preco;
      return 0;
    },
    formatarReal(value) {
      return new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(value);
    },
  },
  mounted() {
    this.getPackageItens()
  },
}
</script>

