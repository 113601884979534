<template>
  <v-container>
    <v-row v-if="isLoading" class="d-flex justify-center">
      <v-progress-circular indeterminate />
    </v-row>
    <v-row v-else dense>
      <v-col cols="12" v-if="itempacote && itempacote.length > 0">
        <h3><b>Itens do Pacote:</b></h3>
        <!-- Mostrar tabela apenas se houver itens -->
        <v-simple-table  dense>
          <thead>
            <tr class="grey lighten-2">
              <th class="text-left">ID</th>
              <th class="text-left">Tipo</th>
              <th class="text-left">Item</th>
              <th class="text-left">Ilimitado</th>
              <th class="text-left">Quantidade</th>
              <th class="text-left">Valor Cobrança</th>
              <th class="text-left">Custo</th>
              <th class="text-left">Início da Vigência</th>
              <th class="text-left">Fim da Vigência</th>
              <th class="text-left">Ativo</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(item, index) in itempacote" :key="index" class="grey lighten-3">
              <td>{{ item.id }}</td>
              <td>{{ getItemType(item) }}</td>
              <td>{{ getItemName(item) }}</td>
              <td>{{ item.ilimitado ? 'Sim' : 'Não' }}</td>
              <td>{{ item.quantidade || '' }}</td>
              <td>{{ formatarReal(getItemCobranca(item)) }}</td>
              <td>{{ formatarReal(getItemCusto(item)) }}</td>
              <td>{{ formatDate(item.inicio_vigencia) }}</td>
              <td>{{ item.fim_vigencia ? formatDate(item.fim_vigencia) : '' }}</td>
              <td>{{ item.ativo ? 'Sim' : 'Não' }}</td>
            </tr>
          </tbody>
        </v-simple-table>
        <!-- Mensagem quando não há itens -->
      </v-col>
      <div v-else >
        <span><b>Sem itens cadastrados</b></span>
      </div>
    </v-row>
  </v-container>
</template>

<script>
import api from '../../../../http';
import UtilsFunc from '../../../../service/utilsFunc';

const { withCRUDUtils } = UtilsFunc

export default {
  name: "OperadoraExpand",
  data: () => withCRUDUtils({
    isLoading: true,
    itempacote: [],
  }),
  props: {
    pacoteId: Number,
    currentItem: Object,
  },
  methods: {
    async getPacote() {
      this.isLoading = true;
      try {
        const { data } = await api.get(`/operadoras/operadora-itempacotes/?operadora=${this.currentItem.id}&pacote=${this.pacoteId}`);
        this.itempacote = data
        .filter(item => item.ativo)
        .sort((a, b) => {
        // Ordenar por ativo
        if (a.ativo !== b.ativo) {
          return b.ativo - a.ativo;
        }
        // Ordenar por taxa
        if (a.taxa && !b.taxa) {
          return -1;
        } else if (!a.taxa && b.taxa) {
          return 1;
        }
        // Ordenar por procedimento
        if (a.procedimento && !b.procedimento) {
          return -1;
        } else if (!a.procedimento && b.procedimento) {
          return 1;
        }
        // Ordenar por equipamento
        if (a.equipamento && !b.equipamento) {
          return -1;
        } else if (!a.equipamento && b.equipamento) {
          return 1;
        }
        // Ordenar por nome de taxa, procedimento, equipamento
        if (a.taxa && b.taxa) {
          return a.taxa.taxa.nome.localeCompare(b.taxa.taxa.nome);
        }
        if (a.procedimento && b.procedimento) {
          return a.procedimento.procedimento.nome.localeCompare(b.procedimento.procedimento.nome);
        }
        if (a.equipamento && b.equipamento) {
          return a.equipamento.equipamento.nome.localeCompare(b.equipamento.equipamento.nome);
        }
        return 0;
      });
      // console.log(this.itempacote)
      } catch (error) {
        this.$toast.error(`Desculpe! Aconteceu algo errado. Deu erro ${error}`);
      } finally {
        this.isLoading = false;
      }
    },
    getItemType(item) {
      if (item.taxa) return 'Taxa';
      if (item.procedimento) return 'Procedimento';
      if (item.equipamento) return 'Equipamento';
      return 'Desconhecido';
    },
    getItemName(item) {
      return item.procedimento ? item.procedimento.procedimento.nome :
             item.equipamento ? item.equipamento.equipamento.nome :
             item.taxa ? item.taxa.taxa.nome :
             'Sem item';
    },

    getItemCusto(item) {
      return item.procedimento ? item.procedimento.procedimento.custo :
             item.equipamento ? item.equipamento.equipamento.custo :
             item.taxa ? item.taxa.taxa.custo :
             0;
    },
    getItemCobranca(item) {
      return item.procedimento ? item.procedimento.preco :
             item.equipamento ? item.equipamento.preco :
             item.taxa ? item.taxa.preco :
             0;
    },

    formatarReal(valor) {
      return Number(valor).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' });
    },

    formatDate(dateString) {
      if (!dateString) return 'Não cadastrada';
      const date = new Date(dateString);
      // Adicionar um dia à data
      date.setDate(date.getDate() + 1);
      return date.toLocaleDateString('pt-BR');
    }
  },
  mounted() {
    this.getPacote();
  },
};
</script>

<style scoped>
.text {
  color: red;
}
h3 {
  margin-bottom: 10px;
  margin-top: 10px;
}
</style>
