<template>
  <v-row v-if="isLoading" class="d-flex justify-center">
    <v-progress-circular indeterminate />
  </v-row>
  <v-row v-else dense>
    <v-row >
      <v-col>
        <p></p>
        <h2><b>Dados da Operadora:</b></h2>
        <p></p>
        <p><b class="afastamento">Operadora: </b> {{ operadoraData.nome }}</p>
        <p><b class="afastamento">Razão Social: </b> {{ operadoraData.razao }}</p>
        <p><b class="afastamento">CNPJ: </b> {{ operadoraData.cnpj }}</p>
        <p><b class="afastamento">ANS: </b> {{ operadoraData.ans }}</p>
        <p><b class="afastamento">Código na Operadora: </b> {{ operadoraData.cod_hc_slz }}</p>
        <p><b class="afastamento">Versão do XML: </b> {{ getVersaoXmlNome(operadoraData.versao_xml) }}</p>
        <p><b class="afastamento">OBS: </b> {{ operadoraData.obs }}</p>
      </v-col>
      <v-col>
        <p></p>
        <h2><b>Contatos</b></h2>
        <h3><b>Autorização:</b></h3>
        <p>
          <b class="afastamento">Contato de Autorização: </b> {{ operadoraData.contato_autorizacao }}
        </p>
        <p>  
          <b class="afastamento">Telefone de Autorização: </b>
            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                <a :href="`https://wa.me/${telAutorizacaoFormatado}`" target="_blank" v-bind="attrs" v-on="on"> {{ operadoraData.tel_autorizacao }} </a>
                <v-icon v-if="operadoraData.tel_autorizacao" color="green">mdi-whatsapp</v-icon>
              </template>
              <span>Chamar no WhatsApp</span>
            </v-tooltip>
        </p>
        <p>
          <b class="afastamento">E-mail da Autorização: </b>
          <v-tooltip top>
            <template v-slot:activator="{ on, attrs }">
              <a :href="`mailto:${operadoraData.email_autorizacao}`" v-bind="attrs" v-on="on">  {{ operadoraData.email_autorizacao }} </a>
              <v-icon v-if="operadoraData.email_autorizacao" color="blue">mdi-email</v-icon>
            </template>
            <span>Enviar Email</span>
          </v-tooltip>
        </p>
        <hr>
        <h3><b>Faturamento:</b></h3>
        <p>
          <b class="afastamento">Contato de Faturamento: </b> {{ operadoraData.contato_faturamento }}
        </p>
        <p>  
          <b class="afastamento">Telefone de Autorização: </b>
            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                <a :href="`https://wa.me/${telFaturamentoFormatado}`" target="_blank" v-bind="attrs" v-on="on"> {{ operadoraData.tel_faturamento }} </a>
                <v-icon v-if="operadoraData.tel_faturamento" color="green">mdi-whatsapp</v-icon>
              </template>
              <span>Chamar no WhatsApp</span>
            </v-tooltip>
        </p>
        <p>
          <b class="afastamento">E-mail da Autorização: </b>
          <v-tooltip top>
            <template v-slot:activator="{ on, attrs }">
              <a :href="`mailto:${operadoraData.email_faturamento}`" v-bind="attrs" v-on="on">  {{ operadoraData.email_faturamento }} </a>
              <v-icon v-if="operadoraData.email_faturamento" color="blue">mdi-email</v-icon>
            </template>
            <span>Enviar Email</span>
          </v-tooltip>
        </p>
        <hr>
        <h3><b>Credenciamento:</b></h3>
        <p>
          <b class="afastamento">Contato de Credenciamento: </b> {{ operadoraData.contato_credenciamento }}
        </p>
        <p>  
          <b class="afastamento">Telefone de Autorização: </b>
            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                <a :href="`https://wa.me/${telCredenciamentoFormatado}`" target="_blank" v-bind="attrs" v-on="on"> {{ operadoraData.tel_credenciamento }} </a>
                <v-icon v-if="operadoraData.tel_credenciamento" color="green">mdi-whatsapp</v-icon>
              </template>
              <span>Chamar no WhatsApp</span>
            </v-tooltip>
        </p>
        <p>
          <b class="afastamento">E-mail da Autorização: </b>
          <v-tooltip top>
            <template v-slot:activator="{ on, attrs }">
              <a :href="`mailto:${operadoraData.email_credenciamento}`" v-bind="attrs" v-on="on">  {{ operadoraData.email_credenciamento }} </a>
              <v-icon v-if="operadoraData.email_credenciamento" color="blue">mdi-email</v-icon>
            </template>
            <span>Enviar Email</span>
          </v-tooltip>
        </p>
      </v-col>
      <!-- <v-col>
        <p></p>
        <h2><b>Procedimentos:</b></h2>
        <div v-for="procedimento in procedimentos" :key="procedimento.id">
          <h4>{{ procedimento.procedimento.nome }}</h4>
          <p><b class="afastamento">Código: </b> {{ procedimento.codigo }} - <b>Tabela: </b> {{ procedimento.tabela.tabela }} - <b>Preço: </b> {{ formatarReal(procedimento.preco) }}</p>
        </div>
      </v-col> -->
    </v-row>
  </v-row>
</template>

<script>
import api from '../../../../http';

export default {
  name: "OperadoraExpand",
  data: () => ({
    isLoading: true,
    versoesxmls: [],
    procedimentos: [],
  }),
  props: {
    operadoraData: {
      type: Object,
      default: () => ({}),
      required: true
    }
  },
  methods:{
    async getVersaoXML() {
      const { data } = await api.get(`/operadoras/versaoxml/`);
      this.versoesxmls = data;
    },
    getVersaoXmlNome(id) {
      const versao = this.versoesxmls.find(v => v.id === id);
      return versao ? versao.versao : 'Não encontrado';
    },
    async getProcedimento() {
      this.isLoading = true
      try {
        const { data } = await api.get(`/operadoras/operadora-procedimentos/`);
        this.procedimentos = data.filter(procedimento => 
          procedimento.operadora &&
          procedimento.operadora.id === this.operadoraData.id &&
          procedimento.ativo);
        this.isLoading = false;
      } catch (error) {
        console.error("Erro ao buscar procedimentos:", error);
      }
    },
    formatarReal(valor) {
      return Number(valor).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' });
    },
  },
  computed: {
    telFaturamentoFormatado() {
      if (!this.operadoraData.tel_faturamento) return '';

      // Remove caracteres não numéricos do telefone
      return this.operadoraData.tel_faturamento.replace(/\D/g, '');
    },
    telCredenciamentoFormatado() {
      if (!this.operadoraData.tel_credenciamento) return '';

      // Remove caracteres não numéricos do telefone
      return this.operadoraData.tel_credenciamento.replace(/\D/g, '');
    },
    telAutorizacaoFormatado() {
      if (!this.operadoraData.tel_autorizacao) return '';

      // Remove caracteres não numéricos do telefone
      return this.operadoraData.tel_autorizacao.replace(/\D/g, '');
    }
  },

  mounted() {
    this.getProcedimento();
    this.getVersaoXML();
  },
};
</script>

<style scoped>
.text {
  color: red;
}
h3 {
  margin-bottom: 10px;
  margin-top: 10px;
}
.afastamento {
  margin-left: 20px;
}

</style>
