<template>
  <div>
    <v-form @submit.prevent="onLocalSubmit" ref="form">
      <v-row class="mt-2" dense>
        <v-col xs="12" sm="12" md="3" lg="3" cols='18'>
          <v-card outlined>
            <v-avatar size="100%" height="120" tile>
              <span v-if="!logoPreview" class="text-center">
                Logomarca
              </span>
              <v-img contain v-else :src="logoPreview"></v-img>
            </v-avatar>
            <v-divider />
            <div class="px-2 pt-4">
              <v-file-input accept="image/png, image/jpeg, image/bmp" label="Logomarca" outlined dense show-size counter
                @change="(e) => onFileSelected(e, 'avatar')" />
            </div>
          </v-card>
        </v-col>
        <v-col>
          <h2 class="blod_color">Dados gerais: </h2>
          <p></p>
          <v-row dense>
            <v-col cols="6">
              <v-text-field v-model="currentItem.nome" clearable :rules="requiredField" dense outlined >
                <template v-slot:label>
                  <span>Operadora / Nome fantasia <span style="color: red;">*</span></span>
                </template>
              </v-text-field>
            </v-col>
            <v-col cols="6">
              <v-text-field v-model="currentItem.razao" clearable :rules="requiredField" dense outlined >
                <template v-slot:label>
                  <span>Razão social <span style="color: red;">*</span></span>
                </template>
              </v-text-field>
            </v-col>
          </v-row>
          <v-row dense>
            <v-col class="main-fields">
              <v-text-field dense outlined v-model="currentItem.cnpj" v-mask="'##.###.###/####-##'"  :rules="requiredField" :error-messages="isCNPJValid === false ? ['CNPJ inválido'] : []" @input="validateCNPJ">
                <template v-slot:label>
                  <span>CNPJ <span style="color: red;">*</span></span>
                </template>
              </v-text-field>
              <v-text-field v-model="currentItem.ans" type="Number" clearable :rules="requiredField" dense outlined>
                <template v-slot:label>
                  <span>ANS <span style="color: red;">*</span></span>
                </template>
              </v-text-field>
              <v-text-field v-model="currentItem.cod_hc_slz" type="Number" clearable :rules="requiredField" dense outlined>
                <template v-slot:label>
                  <span>Código na operadora <span style="color: red;">*</span></span>
                </template>
              </v-text-field>
              <v-autocomplete dense outlined v-model="currentItem.versao_xml" item-value="id" item-text="versao" :items="versoesxmls" :rules="requiredField">
                <template v-slot:label>
                  <span>Versão do XML<span style="color: red;">*</span></span>
                </template>
              </v-autocomplete>
              <v-text-field v-model="formattedDate" type="date" clearable :rules="requiredField" dense outlined>
                <template v-slot:label>
                  <span>Data Contrato / Reajuste <span style="color: red;">*</span></span>
                </template>
              </v-text-field>
            </v-col>
          </v-row>
          <v-row dense>
            <v-col>
              <v-textarea v-model="currentItem.obs" label="Observação" outlined clearable rows="3" />
            </v-col>
          </v-row>
          <h2 class="blod_color">Contatos: </h2>
          <p></p>
          <v-row dense>
            <v-col cols="3">
              <Input v-model="currentItem.contato_autorizacao" label="Responsável pela autorizacao" clearable />
            </v-col>
            <v-col cols="3">
              <Input v-model="currentItem.tel_autorizacao" label="Contato autorizacao" placeholder="Digite (XX) XXXX-XXXX" v-mask="'(##) ##### - ####'" clearable />
            </v-col>
            <v-col cols="6">
              <Input v-model="currentItem.email_autorizacao" label="E-mail da autorizacao" clearable />
            </v-col>
          </v-row>
          <v-row dense>
            <v-col cols="3">
              <Input v-model="currentItem.contato_faturamento" label="Responsável pela faturamento" clearable />
            </v-col>
            <v-col cols="3">
              <Input v-model="currentItem.tel_faturamento" label="Contato faturamento" placeholder="Digite (XX) XXXX-XXXX" v-mask="'(##) ##### - ####'" clearable />
            </v-col>
            <v-col cols="6">
              <Input v-model="currentItem.email_faturamento" label="E-mail da faturamento" clearable />
            </v-col>
          </v-row>
          <v-row dense>
            <v-col cols="3">
              <Input v-model="currentItem.contato_credenciamento" label="Responsável pela credenciamento" clearable />
            </v-col>
            <v-col cols="3">
              <Input v-model="currentItem.tel_credenciamento" label="Contato credenciamento" placeholder="Digite (XX) XXXX-XXXX" v-mask="'(##) ##### - ####'" clearable />
            </v-col>
            <v-col cols="6">
              <Input v-model="currentItem.email_credenciamento" label="E-mail da credenciamento" clearable />
            </v-col>
          </v-row>
          <h2 class="blod_color">Endereço: </h2>
          <p></p>
          <v-row dense>
            <v-col class="main-fields">
              <Input label="CEP" @change="autofillAddressFromCep" v-mask="'#####-###'" v-model="currentItem.cep"
                clearable />
              <Input label="Logradouro" v-model="currentItem.logradouro" clearable />
              <Input label="Número" v-model="currentItem.numero" clearable />
              <v-autocomplete dense label="Estado" v-model="currentItem.estado" outlined :loading="isLoading('get:ufs')"
                :items="ufs" item-value="sigla" item-text="sigla" return-object @change="getLocales" clearable />
              <v-autocomplete dense label="Município" v-model="currentItem.municipio" outlined
                :loading="isLoading('get:locales')" :items="locales" item-value="localidade" item-text="localidade"
                return-object clearable />
              <Input label="Bairro" v-model="currentItem.bairro" clearable />
              <Input label="Complemento" v-model="currentItem.complemento" clearable />
            </v-col>
          </v-row>

        </v-col>
      </v-row>
      <v-row dense>
        <v-col>
          <v-btn-toggle v-model="currentItem.ativo" mandatory class="white--text">
            <v-btn :color="!currentItem.ativo ? 'error' : ''">Inativo</v-btn>
            <v-btn :color="currentItem.ativo ? 'green' : ''">Ativo</v-btn>
          </v-btn-toggle>
        </v-col>
      </v-row>
      <v-row dense>
        <v-col class="text-end">
          <v-tooltip top :disabled="isFormValid">
            <template v-slot:activator="{ on, attrs }">
              <span v-on="on" v-bind="attrs">
                <ConfirmButton :color="isFormValid ? 'success' : 'grey'" :loading="isSubmitting" :disabled="!isFormValid || isSubmitting" > Salvar </ConfirmButton>
              </span>
            </template>
            <span>Preencha todos os campos obrigatórios (*) para habilitar o botão.</span>
          </v-tooltip>
        </v-col>
      </v-row>
    </v-form>
  </div>
</template>

<script>
import Input from '../../../Input.vue';
import UtilsFunc from '../../../../service/utilsFunc';
import api from '../../../../http';
import ConfirmButton from '../../../ConfirmButton.vue';
import axios from "axios"
import '../../../../assets/css/styles.css';

const { withCRUDUtils } = UtilsFunc

function validarCNPJ(cnpj) {
    cnpj = cnpj.replace(/[^\d]+/g, '');
    if (cnpj === '') return false;
    if (cnpj.length !== 14) return false;

    // Elimina CNPJs inválidos conhecidos
    if (
        cnpj === '00000000000000' ||
        cnpj === '11111111111111' ||
        cnpj === '22222222222222' ||
        cnpj === '33333333333333' ||
        cnpj === '44444444444444' ||
        cnpj === '55555555555555' ||
        cnpj === '66666666666666' ||
        cnpj === '77777777777777' ||
        cnpj === '88888888888888' ||
        cnpj === '99999999999999'
    ) {
        return false;
    }

    // Valida DVs
    let tamanho = cnpj.length - 2;
    let numeros = cnpj.substring(0, tamanho);
    let digitos = cnpj.substring(tamanho);
    let soma = 0;
    let pos = tamanho - 7;
    for (let i = tamanho; i >= 1; i--) {
        soma += numeros.charAt(tamanho - i) * pos--;
        if (pos < 2) pos = 9;
    }
    let resultado = soma % 11 < 2 ? 0 : 11 - (soma % 11);
    if (resultado !== parseInt(digitos.charAt(0))) return false;

    tamanho += 1;
    numeros = cnpj.substring(0, tamanho);
    soma = 0;
    pos = tamanho - 7;
    for (let i = tamanho; i >= 1; i--) {
        soma += numeros.charAt(tamanho - i) * pos--;
        if (pos < 2) pos = 9;
    }
    resultado = soma % 11 < 2 ? 0 : 11 - (soma % 11);
    if (resultado !== parseInt(digitos.charAt(1))) return false;

    return true;
}

export default {
  name: "BaseForm",
  props: {
    currentItem: Object,
    loading: Boolean,
    onSubmit: Function
  },
  data: () => withCRUDUtils({
    doctors: [],
    types: [],
    versoesxmls: [],
    ufs: [],
    locales: [],
    logoPreview: null,
    isSubmitting: false,
    requiredField: [UtilsFunc.isEmptyField('Obrigatório')],
    filesToUpload: {},
    isCNPJValid: null,
  }),
  components: { Input, ConfirmButton },
  methods: {
    onLocalSubmit() {
      const isValidForm = this.$refs.form.validate();
      if (!isValidForm) {
        this.$toast.error("Por favor, preencha todos os campos obrigatórios.");
        return;
      }
      const formatedFields = { ...this.currentItem }
      if (this.filesToUpload.certificado) {
        formatedFields.certificado = this.filesToUpload.certificado
      }
      if (this.currentItem.estado) {
        formatedFields.estado = this.currentItem.estado.sigla
      }
      if (this.currentItem.municipio) {
        formatedFields.municipio = this.currentItem.municipio.localidade
      }
      if (this.currentItem.versaao_xml) {
        formatedFields.versao_xml = this.currentItem.versao_xml.versao
      }
       const cnpjNumber = this.currentItem.cnpj.replace(/[^\d]/g, '');
      if (!validarCNPJ(cnpjNumber)) {
        this.$toast.error("CNPJ inválido.");
        return;
      }
      const fields = { ...formatedFields, logo: this.filesToUpload.avatar, cnpj: cnpjNumber }
      this.isSubmitting = true;
      this.onSubmit(fields);
      this.isSubmitting = false;
    },
    onFileSelected(event, fieldName) {
      const currentItemField = "avatar"
      const previewName = "logoPreview"
      if (!event) {
        const defaultFile = this.currentItem[currentItemField];
        this[previewName] = defaultFile;
      } else {
        this.filesToUpload[fieldName] = event;
        this[previewName] = window.URL.createObjectURL(event);
      }
    },
    async getLocales(uf) {
      const LOADING_NAME = "get:locales";
      this.setLoading(LOADING_NAME);
      const { data } = await api.get(`/uteis/localidades/?uf=${uf.sigla}`);
      this.setLoading(LOADING_NAME, true);
      this.locales = data;
    },
    async getVersaoXML() {
      const LOADING_NAME = "get:versao_xml";
      this.setLoading(LOADING_NAME);
      const { data } = await api.get(`/operadoras/versaoxml/`);
      this.setLoading(LOADING_NAME, true);
      this.versoesxmls = data;
    },
    async getUFS() {
      const LOADING_NAME = "get:ufs";
      this.setLoading(LOADING_NAME);
      const { data } = await api.get(`uteis/uf/`);
      this.ufs = data;
      this.setLoading(LOADING_NAME, true);
    },
    resetFields() {
      this.currentItem.bairro = null
      this.currentItem.estado = null
      this.currentItem.municipio = null
      this.currentItem.logradouro = null
      this.currentItem.complemento = null
    },
    async autofillAddressFromCep(cep) {
      try {
        this.resetFields()
        const { data } = await axios.get(`https://viacep.com.br/ws/${cep}/json/`);
        const uf = this.ufs.find(({ sigla }) => sigla === data.uf);
        await this.getLocales(uf);
        const localidade = this.locales.find(({ localidade }) => localidade === data.localidade);
        this.currentItem.bairro = data.bairro
        this.currentItem.estado = uf
        this.currentItem.municipio = localidade
        this.currentItem.logradouro = data.logradouro
        this.currentItem.complemento = data.complemento
      }
      catch (error) {
        this.resetFields()
        this.$toast.error("CEP não encontrado");
      }
    },
    async getTypes() {
      const { data } = await api.get("/multi_empresas/tipos/")
      this.types = data
    },
     validateCNPJ() {
      const cnpjNumber = this.currentItem.cnpj.replace(/[^\d]/g, '');
      this.isCNPJValid = validarCNPJ(cnpjNumber);
    },
  },
  computed: {
    isFormValid() {
      // Verifica se todos os campos obrigatórios estão preenchidos para poder habilitar o botão de Salvar
      return !!( this.currentItem.nome && 
        this.currentItem.razao && 
        this.currentItem.cnpj && 
        this.currentItem.ans &&
        this.currentItem.versao_xml &&
        this.currentItem.datacontrato &&
        this.currentItem.cod_hc_slz);
    },
    requiredAsterisk() {
      return '<span style="color: red;">*</span>';
    },
    formattedDate: {
      get() {
        // Verifica se a data atual está definida
        if (this.currentItem.datacontrato) {
          // Formata a data para "yyyy-MM-dd"
          return this.currentItem.datacontrato.split('T')[0];
        }
        // Retorna vazio se a data não estiver definida
        return '';
      },
      set(value) {
        // Define o valor formatado da data de volta no currentItem.datacontrato
        this.currentItem.datacontrato = value;
      }
    }
  },
  mounted() {
    if (this.currentItem.logo) this.logoPreview = this.currentItem.logo
    this.currentItem.ativo = Number(this.currentItem.ativo)
    this.getUFS();
    this.getVersaoXML();
    this.getTypes()
    if (this.currentItem.estado) {
      const uf = {
        sigla: this.currentItem.estado
      }
      this.getLocales(uf)
    }
  },
}
</script>

<style scoped>
.main-fields {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(220px, 1fr));
  column-gap: 1rem;
}
</style>
