<template>
  <v-form @submit.prevent="onLocalSubmit" ref="formRef">
    <v-row>
      <v-col>
        <v-text-field v-model="currentItem.procedimento.nome" outlined dense readonly label="Procedimento" >
        </v-text-field>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-text-field v-model="currentItem.nomecomercial" outlined dense clearable label="Nome Comercial no Contratante" >
        </v-text-field>
      </v-col>
    </v-row>
    <v-row dense>
      <v-col>
          <v-text-field v-model="currentItem.codigo" dense clearable outlined type="number" :rules="requiredField">
            <template v-slot:label>
              <span>Código do Procedimento <span style="color: red;">*</span></span>
            </template>
          </v-text-field>
          <span style="position: absolute; top: 27%; right: 410px; transform: translateY(20%);" >Código com {{ currentItem.codigo ? currentItem.codigo.toString().length : 0 }} dígitos</span>
      </v-col>
      <v-col>
        <vuetify-money v-model="currentItem.preco" label="Preço R$" outlined clearable v-money="currencyMask" :options="options" dense />
      </v-col>
      <v-col cols='2'>
        <div class="d-flex justify-center align-center"  v-if="currentItem.procedimento">
          <v-icon v-if="custoEstimado < 0" color="red">mdi-currency-usd-off</v-icon>
          <v-icon v-else color="green">mdi-currency-usd</v-icon>
        </div>
        <div class="small-text" v-if="currentItem.procedimento">
          {{'Mínimo R$ '+(currentItem.procedimento.custo * (1 +((this.empresa[0]?.custo_fixo + this.empresa[0]?.imposto + this.empresa[0]?.rentabilidade) / 100))).toFixed(2)}}
        </div>
      </v-col>
    </v-row>
    <v-row dense>
      <v-col>
        <v-autocomplete v-model="currentItem.tabela" validate-on-blur :items="tableValues" item-value="id"
          :item-text="tabelaText" return-object outlined dense clearable :rules="requiredField" >
          <template v-slot:label>
            <span>Código da Tabela - Descrição <span style="color: red;">*</span></span>
          </template>
        </v-autocomplete>
      </v-col>
      <!-- <v-col>
        <v-text-field :value="tabelaNome" label="Tabela Selecionada"  readonly  outlined  dense> </v-text-field>
      </v-col> -->
    </v-row>
    <v-row dense>
      <v-col>
        <v-autocomplete v-model="currentItem.despesa" validate-on-blur :items="despesaValues" item-value="id"
          :item-text="despesaText" return-object outlined dense clearable :rules="requiredField" >
          <template v-slot:label>
            <span>Código da Despesa - Descrição <span style="color: red;">*</span></span>
          </template>
        </v-autocomplete>
      </v-col>
      <v-col>
        <v-autocomplete v-model="currentItem.tipoguia" validate-on-blur  :items="tipoguiaValues" item-value="id" 
          :item-text="tipoguiaText" return-object outlined dense clearable :rules="requiredField" >
          <template v-slot:label>
            <span>Codigo do Tipo de Guia - Descrição <span style="color: red;">*</span></span>
          </template>
        </v-autocomplete> 
      </v-col>
    </v-row>
    <v-row dense>
    </v-row>
    <v-row dense>
      <v-col>
        <v-autocomplete v-model="currentItem.xmlagrupado" validate-on-blur  :items="xmlagrupadoValues" item-value="id" 
          :item-text="xmlagrupadoText" return-object outlined dense clearable :rules="requiredField" >
          <template v-slot:label>
            <span>Forma de geração no XML (Procedimento) <span style="color: red;">*</span></span>
          </template>
        </v-autocomplete> 
      </v-col>
      <v-col>
        <v-autocomplete v-model="currentItem.unidade_medida" validate-on-blur  :items="unidade" item-value="id" 
          :item-text="unidadeText" return-object outlined dense clearable :rules="requiredField" >
          <template v-slot:label>
            <span>Unidade de Medida <span style="color: red;">*</span></span>
          </template>
        </v-autocomplete> 
      </v-col>
      </v-row>
    <v-row dense>
      <v-text-field v-model="currentItem.inicio_vigencia" type="date" outlined clearable dense :rules="requiredField" class="mr-2">
        <template v-slot:label>
            <span>Início da vigência <span style="color: red;">*</span></span>
        </template>
      </v-text-field>
      <v-text-field v-model="currentItem.fim_vigencia" type="date"  label="Fim da vigência" outlined clearable dense>
      </v-text-field>
    </v-row>
    <v-row dense>
      <v-col>
        <label>
          Status
        </label>
        <div class="d-flex">
          <v-btn-toggle v-model="currentItem.ativo" mandatory class="white--text">
            <v-btn :color="!currentItem.ativo ? 'error' : ''">
              Inativo
            </v-btn>
            <v-btn :color="currentItem.ativo ? 'green' : ''">
              Ativo
            </v-btn>
          </v-btn-toggle>
        </div>
      </v-col>
    </v-row>
    <v-row dense>
      <v-col class="text-end">
          <v-tooltip top :disabled="isFormValid">
            <template v-slot:activator="{ on, attrs }">
              <span v-on="on" v-bind="attrs">
                <ConfirmButton :color="isFormValid ? 'success' : 'grey'" :loading="isSubmitting" :disabled="!isFormValid || isSubmitting">Salvar</ConfirmButton>
              </span>
            </template>
            <span>Preencha todos os campos obrigatórios (*) para habilitar o botão.</span>
          </v-tooltip>
        </v-col>
    </v-row>
  </v-form>
</template>

<script>
import UtilsFunc from '@/service/utilsFunc';
import ConfirmButton from '../../../ConfirmButton.vue';
import api from '../../../../http';

export default {
  name: "ProcedureForm",
  props: {
    currentItem: {type:Object,default:() => {
      return { 
        tabela:{nome:''},  
        despesa:{nome:''},
        tipoguia:{nome:''},
        xmlagrupado:{nome:''},
        unidade_medida:{nome:''},
      }
      }},
    loading: Boolean,
    onSubmit: Function,
    
  },
  data: () => UtilsFunc.withCRUDUtils({
    procedures: [],
    tableValues: [],
    empresa: [],
    despesaValues: [],
    tipoguiaValues: [], 
    xmlagrupadoValues: [],
    unidade: [],
    isSubmitting: false,
    requiredField: [e => (e && e !== "") || 'Obrigatório'],
    options: {
      locale: "pt-BR",
      prefix: "R$",
      suffix: "",
      length: 11,
      precision: 2
    },
    currencyMask: {
      decimal: ',',
      thousands: '.',
      precision: 2,
    },
  }),
  computed: {
    tipoguiaText() {
      return (item) => {
          return `${item.tipoguia} - ${item.nome}`;
      }
    },
    tabelaText() {
      return (item) => {
          return `${item.tabela} - ${item.nome}`;
      }
    },
    despesaText() {
      return (item) => {
          return `${item.despesa} - ${item.nome}`;
      }
    },
    xmlagrupadoText() {
      return (item) => {
          return ` ${item.nome}`;
      }
    },
    unidadeText() {
      return (item) => {
          return `${item.codigo} - ${item.nome}`;
      }
    },
     requiredAsterisk() {
      return '<span style="color: red;">*</span>';
    },
    isFormValid() {
    // Verifica se todos os campos obrigatórios estão preenchidos
    return !! (this.currentItem.procedimento && 
      this.currentItem.codigo && 
      this.currentItem.tabela && 
      this.currentItem.despesa && 
      this.currentItem.tipoguia && 
      this.currentItem.inicio_vigencia && 
      this.currentItem.unidade_medida &&
      this.currentItem.xmlagrupado); 
    },
    custoEstimado() {
      if (this.empresa.length && this.currentItem.procedimento) {
        const empresa = this.empresa[0];
        const custo = parseFloat(this.currentItem.procedimento.custo);
        const imposto = (parseFloat(empresa.custo_fixo) + parseFloat(empresa.imposto) + parseFloat(empresa.rentabilidade)) / 100;
        const resultado = custo * (1 + imposto);
        const resultadofinal = this.currentItem.preco - resultado
        return resultadofinal.toFixed(2);
      }
      return null;
    },
  },
  methods: {
    onLocalSubmit() {
      const isValid = this.$refs.formRef.validate();
      if (!isValid) {
        this.$toast.error("Por favor, preencha todos os campos obrigatórios.");
        return;
      }
      this.isSubmitting = true;
      const { operadora, procedimento, tabela, despesa, tipoguia, xmlagrupado, unidade_medida  } = this.currentItem
      this.onSubmit({
        ...this.currentItem,
        operadora: operadora ? operadora.id : undefined,
        ativo: Boolean(this.currentItem.ativo),
        procedimento: procedimento.id,
        tabela: tabela.id,
        despesa: despesa.id,
        tipoguia: tipoguia.id,
        xmlagrupado: xmlagrupado.id,
        unidade_medida: unidade_medida.id,
        inicio_vigencia: this.currentItem.inicio_vigencia,
        fim_vigencia: this.currentItem.fim_vigencia,
      }).catch((error) => {
        this.$toast.error(`Desculpe. Apresentou algum erro. ${error}`);
      }).finally(() => {
        this.isSubmitting = false;
      });
    },
    async getTableValues() {
      const LOADING_NAME = 'get:tableValues'
      this.setLoading(LOADING_NAME)
      try {
        const { data } = await api.get('operadoras/tabelas/')
        this.tableValues = data.filter(tabela => tabela.ativo)
      } catch (error) {
        this.$errorHandler(error)
      } finally {
        this.setLoading(LOADING_NAME, true)
      }
    },
    async getEmpresa() {
      const LOADING_NAME = 'get:tableValues'
      this.setLoading(LOADING_NAME)
      try {
        const { data } = await api.get('/multi_empresas/empresas/')
        this.empresa = data.filter(item => item.filial == 2)
      } catch (error) {
        this.$errorHandler(error)
      } finally {
        this.setLoading(LOADING_NAME, true)
      }
    },
    async getDespesaValues() {
      const LOADING_NAME = 'get:despesaValues'
      this.setLoading(LOADING_NAME)
      try {
        const { data } = await api.get('operadoras/despesas/')
        this.despesaValues = data.filter(despesa => despesa.ativo)
      } catch (error) {
        this.$errorHandler(error)
      } finally {
        this.setLoading(LOADING_NAME, true)
      }
    },
    async getTipoguiaValues() {
      const LOADING_NAME = 'get:tipoguiaValues'
      this.setLoading(LOADING_NAME)
      try {
        const { data } = await api.get('operadoras/tipoguia/')

        this.tipoguiaValues = data.filter(tipoguia => tipoguia.ativo)
      } catch (error) {
        this.$errorHandler(error)
      } finally {
        this.setLoading(LOADING_NAME, true)
      }
    },
    async getXmlagrupadoValues() {
      const LOADING_NAME = 'get:xmlagrupadoValues'
      this.setLoading(LOADING_NAME)
      try {
        const { data } = await api.get('operadoras/xmlagrupado/')
        this.xmlagrupadoValues = data.filter(xmlagrupado => xmlagrupado.ativo)
      } catch (error) {
        this.$errorHandler(error)
      } finally {
        this.setLoading(LOADING_NAME, true)
      }
    },
    async getUnidadeMedida() {
      const LOADING_NAME = 'get:unidade'
      this.setLoading(LOADING_NAME)
      try {
        const { data } = await api.get('uteis/unidade/')
        this.unidade = data.filter(item => item.ativo)
        // console.log(this.unidade)
      } catch (error) {
        this.$errorHandler(error)
      } finally {
        this.setLoading(LOADING_NAME, true)
      }
    },
    async getProcedures() {
      const { data } = await api.get("procedimentos/procedimentos/");
      this.procedures = data.filter(procedimento => procedimento.ativo);
    },
    checkAndSetStatus() {
      if (this.currentItem.fim_vigencia) {
        const today = new Date();
        today.setHours(0, 0, 0, 0); // Zera o horário para a comparação se concentrar apenas na data
        const fimVigencia = new Date(this.currentItem.fim_vigencia);
        fimVigencia.setHours(0, 0, 0, 0); // Zera o horário

        if (fimVigencia < today) {
          this.currentItem.ativo = 0; // Define como inativo
        } else {
          this.currentItem.ativo = 1; // Define como ativo
        }
      } else {
        this.currentItem.ativo = 1; // Define como ativo se a data fim_vigencia estiver vazia
      }
    },
  },
  components: { ConfirmButton },
  mounted() {
    this.currentItem.ativo = Number(this.currentItem.ativo)
    this.getTableValues()
    this.getDespesaValues()
    this.getTipoguiaValues()
    this.getXmlagrupadoValues()
    this.getProcedures()
    this.getProcedures()
    this.getEmpresa()
    this.getUnidadeMedida()
    this.currentItem.preco = this.currentItem.preco || '0.00';
  },
   watch: {
    'currentItem.fim_vigencia': function() {
      this.checkAndSetStatus();
    },
    'currentItem.inicio_vigencia'(newValue) {
      const today = new Date().toISOString().split('T')[0];
      this.currentItem.ativo = newValue === today ? 1 : 0;
    },
  },
}
</script>

<style scoped>
  .small-text {
  font-size: smaller;
}
</style>
