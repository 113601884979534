<template>
  <div>
    <div class="d-flex justify-end mb-3 mr-2">
      <v-btn color="success" class="elevation-0 " @click="dialog.create = true">
        <v-icon left> mdi-plus </v-icon>
        Novo
      </v-btn>
    </div>
    <v-dialog v-if="dialog.create" v-model="dialog.create" max-width="800">
      <v-card>
        <v-card-title class="blod_color">
          Novo Item do Pacote
          <v-spacer></v-spacer>
          <v-btn icon @click="dialog.create = false">
            <v-icon>
              mdi-close
            </v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text>
          <ProcedureFormItemPacote :loading="isLoading('post:itempacote')" :currentItem2="selectedItem"
            :onClose="onCreateItem" :pacoteId="pacoteId" :idoperadora="idoperadora"></ProcedureFormItemPacote>
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-data-table :loading="isLoading('get:itenspacote')" :headers="headers" :items="itempacote" :items-per-page="5"
      item-key="id" class="elevation-1 mb-4">
      <template v-slot:[`item.tipo`]="{ item }">
        <span>{{ getItemType(item) }}</span>
      </template>
      <template v-slot:[`item.nome`]="{ item }">
        <span>{{ getItemNome(item) }}</span>
      </template>
      <template v-slot:[`item.custo`]="{ item }">
        <span>{{ (getCusto(item)) }}</span>
      </template>
      <template v-slot:[`item.ilimitado`]="{ item }">
        <span>{{ item.ilimitado ? 'SIM' : 'NÃO' }}</span>
      </template>
      <template v-slot:[`item.quantidade`]="{ item }">
        <span>{{ item.quantidade ?? '' }}</span>
      </template>
      <template v-slot:[`item.ativo`]="{ item }">
        <v-chip :color="item.ativo ? 'green' : 'red'" dark>
          {{ item.ativo ? 'Ativo' : 'Inativo' }}
        </v-chip>
      </template>
      <template v-slot:[`item.inicio_vigencia`]="{ item }">
        <span>{{ formatarData(item.inicio_vigencia) }}</span>
      </template>
      <template v-slot:[`item.fim_vigencia`]="{ item }">
        <span>{{ formatarData(item.fim_vigencia) }}</span>
      </template>
      <template v-slot:[`item.edit`]="{ item }">
        <v-tooltip top>
          <template v-slot:activator="{ on, attrs }">
            <v-btn icon v-bind="attrs" v-on="on" @click="selectItemForEdit(item, 'update')">
              <v-icon color="green">mdi-pencil</v-icon>
            </v-btn>
          </template>
          <span>Clique para editar</span>
        </v-tooltip>
      </template>
    </v-data-table>

    <v-dialog v-if="dialog.update" v-model="dialog.update" max-width="800">
      <v-card>
        <v-card-title class="blod_color">
          Editar Item: Id {{ selectedItem.id }} - {{ selectedItem ? getItemType(selectedItem) : '' }} - {{ selectedItem ?
            getItemNome(selectedItem) : '' }}
          <v-spacer></v-spacer>
          <v-btn icon @click="dialog.update = false">
            <v-icon>
              mdi-close
            </v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text>
          <ProcedureFormEditItemPacoteIndividualizado :loading="isLoading('put:itempacote')" :currentItem="selectedItem"
            :onSubmit="onSubmitItemPacote" :idoperadora="idoperadora"/>
        </v-card-text>
      </v-card>
    </v-dialog>

  </div>
</template>

<script>
import UtilsFunc from '@/service/utilsFunc';
// import ConfirmButton from '../../../ConfirmButton.vue';
import ProcedureFormEditItemPacoteIndividualizado from '../operadoras/ProcedureFormEditItemPacoteIndividualizado.vue';
import ProcedureFormItemPacote from './ProcedureFormItemPacote.vue';
import api from '../../../../http';

export default {
  name: "PacoteFormEditItemPacote",
  components: { ProcedureFormEditItemPacoteIndividualizado, ProcedureFormItemPacote },
  props: {
    currentItem: {
      type: Object, default: () => {
      }
    },
    loading: Boolean,
    onSubmit: Function,
    operadoraId: Number,
    pacoteId: Number,
    idoperadora: Number,
  },
  data: () => UtilsFunc.withCRUDUtils({
    itempacote: [],
    updateItemDialog: false,
    isSubmitting: false,
    selectedItem: null,
    dialog: {
      create: false,
    },
    requiredField: [e => (e && e !== "") || 'Obrigatório'],
    headers: [
      { text: "ID", value: "id" },
      { text: "Editar", value: "edit" },
      { text: "Tipo", value: "tipo" },
      { text: "Item", value: "nome" },
      { text: "Custo", value: "custo" },
      { text: "Ilimitado", value: "ilimitado" },
      { text: "Quantidade", value: "quantidade" },
      { text: "Inicio vigência", value: "inicio_vigencia" },
      { text: "Fim Vigência", value: "fim_vigencia" },
      { text: "Ativo", value: "ativo" },
    ],
  }),
  computed: {
    requiredAsterisk() {
      return '<span style="color: red;">*</span>';
    },
    isFormValid() {
      // Verifica se todos os campos obrigatórios estão preenchidos
      return !!(this.currentItem.nome &&
        this.currentItem.xmlagrupado);
    },
  },
  methods: {
    onLocalSubmit() {
      const isValid = this.$refs.formRef.validate();
      if (!isValid) {
        this.$toast.error("Por favor, preencha todos os campos obrigatórios.");
        return;
      }
      this.isSubmitting = true;
      const { operadora, id, tabela, despesa, tipoguia, xmlagrupado, modalidade } = this.currentItem
      this.onSubmit({
        ...this.currentItem,
        operadora: operadora ? operadora.id : undefined,
        ativo: Boolean(this.currentItem.ativo),
        id: id,
        modalidade: modalidade.id,
        tabela: tabela.id,
        despesa: despesa.id,
        tipoguia: tipoguia.id,
        xmlagrupado: xmlagrupado.id,
        inicio_vigencia: this.currentItem.inicio_vigencia,
        fim_vigencia: this.currentItem.fim_vigencia,
      }
      ).catch((error) => {
        this.$toast.error(`Desculpe. Apresentou algum erro. ${error}`);
      }).finally(() => {
        this.isSubmitting = false;
      });
    },
    async getItensPacote() {
  const LOADING_NAME = 'get:itenspacote';
  this.setLoading(LOADING_NAME);
  try {
    const { data } = await api.get(`operadoras/operadora-itempacotes/?operadora=${this.operadoraId}&pacote=${this.pacoteId}`);
    this.itempacote = data.sort((a, b) => {
      // Ordenar por ativo
      if (a.ativo !== b.ativo) {
        return b.ativo - a.ativo;
      }
      // Ordenar por taxa
      if (a.taxa && !b.taxa) {
        return -1;
      } else if (!a.taxa && b.taxa) {
        return 1;
      }
      // Ordenar por procedimento
      if (a.procedimento && !b.procedimento) {
        return -1;
      } else if (!a.procedimento && b.procedimento) {
        return 1;
      }
      // Ordenar por equipamento
      if (a.equipamento && !b.equipamento) {
        return -1;
      } else if (!a.equipamento && b.equipamento) {
        return 1;
      }
      // Ordenar por nome de taxa, procedimento, equipamento
      if (a.taxa && b.taxa) {
        return a.taxa.taxa.nome.localeCompare(b.taxa.taxa.nome);
      }
      if (a.procedimento && b.procedimento) {
        return a.procedimento.procedimento.nome.localeCompare(b.procedimento.procedimento.nome);
      }
      if (a.equipamento && b.equipamento) {
        return a.equipamento.equipamento.nome.localeCompare(b.equipamento.equipamento.nome);
      }
      return 0;
    });
    // console.log('GetItemPacote --> ', this.itempacote);
  } catch (error) {
    this.$errorHandler(error);
    this.$toast.error(`Desculpe! Aconteceu algo errado. Deu erro ${error}`);
  } finally {
    this.setLoading(LOADING_NAME, true);
  }
},
    async onCreateItem() {
      this.dialog.create = false
      this.getItensPacote()
    },
    async putItemPacote(fields) {
      const LOADING_NAME = 'put:itempacote'
      this.setLoading(LOADING_NAME)

      try {
        this.isSubmitting = true;
        // console.log('Put Pacote -> ',fields)
        await api.put(`/operadoras/operadora-pacote/${fields.id}/`, fields)
        await this.getPacote()
        this.$toast.success('Item do Pacote atualizado com sucesso!')
        this.dialog.update = false
      } catch (error) {
        this.$toast.error(`Desculpe! Aconteceu algo errado. Deu erro ${error}`)
      } finally {
        this.setLoading(LOADING_NAME, true)
        this.isSubmitting = false;
      }
    },
    checkAndSetStatus() {
      if (this.currentItem.fim_vigencia) {
        const today = new Date();
        today.setHours(0, 0, 0, 0); // Zera o horário para a comparação se concentrar apenas na data
        const fimVigencia = new Date(this.currentItem.fim_vigencia);
        fimVigencia.setHours(0, 0, 0, 0); // Zera o horário

        if (fimVigencia < today) {
          this.currentItem.ativo = 0; // Define como inativo
        } else {
          this.currentItem.ativo = 1; // Define como ativo
        }
      } else {
        this.currentItem.ativo = 1; // Define como ativo se a data fim_vigencia estiver vazia
      }
    },
    formatarData(data) {
      if (!data) return '';
      const [ano, mes, dia] = data.split('-');
      return `${dia}/${mes}/${ano}`;
    },
    formatCurrencyZero(value) {
      return new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(value)
    },
    getItemType(item) {
      if (item.taxa) return 'Taxa';
      if (item.procedimento) return 'Procedimento';
      if (item.equipamento) return 'Equipamento';
      return 'Desconhecido';
    },
    getItemNome(item) {
      if (item.taxa) return item.taxa.taxa.nome ? item.taxa.taxa.nome : '';
      if (item.procedimento) return item.procedimento.procedimento.nome ? item.procedimento.procedimento.nome : '';
      if (item.equipamento) return item.equipamento.equipamento.nome ? item.equipamento.equipamento.nome : '';
      return 'Sem Nome';
    },
    getCusto(item) {
      let custo = 0;
      if (item.taxa) custo = item.taxa.taxa.custo;
      else if (item.procedimento) custo = item.procedimento.procedimento.custo;
      else if (item.equipamento) custo = item.equipamento.equipamento.custo;

      // Retorna o custo formatado como moeda brasileira
      return custo ? this.formatCurrencyZero(custo) : 'R$ 0,00';
    },
    openItemEditDialog() {
      this.updateItemDialog = true;
    },
    async onSubmitItemPacote(fields) { // falta o código para editar 
      try {
        this.isSubmitting = true;
        let response = await api.put(`/operadoras/operadora-itempacote/${fields.id}/`, fields);
        if (response.status === 201 || response.status === 200) {
          this.$toast.success('Item do Pacote editado com sucesso!');
        } else {
          throw new Error('Falha ao editar item');
        }
        this.dialog.update = false
        this.getItensPacote()
      } catch (error) {
        this.$toast.error(`Erro ao editar item do pacote: ${error.message || error}`);
      } finally {
        this.isSubmitting = false;
      }
    },
    openNewItemDialog() {
      this.dialog.create = true;
    },
    selectItemForEdit(item) {
      this.selectedItem = item;
      this.dialog.update = true;
    },
  },
  mounted() {
    this.getItensPacote()
  },
  watch: {
    'currentItem.fim_vigencia': function () {
      this.checkAndSetStatus();
    },
    'currentItem.inicio_vigencia'(newValue) {
      const today = new Date().toISOString().split('T')[0];
      this.currentItem.ativo = newValue === today ? 1 : 0;
    },
  },
}
</script>

<style scoped>
.small-text {
  font-size: smaller;
}

.elevation-1 {
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 1px 5px 0 rgba(0, 0, 0, 0.12);
}
</style>
