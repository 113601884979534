<template>
  <div>
    <v-data-table :loading="isLoading('get:procedures')" :headers="headers" :items="formattedData"
      :server-items-length="-1" class="mb-4" :sort-by.sync="defaultSort.name" :sort-desc.sync="defaultSort.isDesc"
      v-model="selectedRows" show-select :search="search">
      <template v-slot:top>
        <v-row class="mt-2">
          <v-col cols="12">
            <v-row>
              <v-col cols="5">
                <v-text-field dense outlined v-model="search" label="Pesquisar" class="mx-0" append-icon="mdi-magnify"
                  clearable />
              </v-col>
              <v-col class="text-end">
                <v-btn class="mr-4 elevation-0" text @click="getOperatorProcedures">
                  <v-icon left>
                    mdi-reload
                  </v-icon>
                  atualizar
                </v-btn>
                <v-btn color="info" class="elevation-0 mr-4" :disabled="selectedRows.length < 1"
                  @click="dialog.duplicate = true">
                  <v-icon left>
                    mdi-content-copy
                  </v-icon>
                  Reajuste
                </v-btn>
                <v-btn color="success" class="elevation-0" @click="dialog.create = true">
                  <v-icon left>
                    mdi-plus
                  </v-icon>
                  Novo
                </v-btn>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </template>
      <template v-slot:[`item.ativo`]="{ item }">
        <v-chip v-if="item.ativo === true" color="blue" class="white--text">ATIVA</v-chip>
        <v-chip v-else>INATIVA</v-chip>
      </template>
      <template v-slot:[`item.edit`]="{ item }">
            <v-tooltip top>
                <template v-slot:activator="{ on, attrs }">
                    <v-btn icon v-bind="attrs" v-on="on" @click="onSelectRow(item, 'update')">
                        <v-icon color="green">mdi-pencil</v-icon>
                    </v-btn>
                </template>
                <span>Clique para editar</span>
            </v-tooltip>
        </template>
      <template v-slot:[`item.custoEstimado`]="{ item }">
        <v-col cols='1'>
          <div class="d-flex justify-center align-cente" >
            <v-icon v-if="item.equipamento && empresa[0] && (item.preco - (item.equipamento.custo * (1 +((empresa[0].custo_fixo + empresa[0].imposto + empresa[0].rentabilidade) / 100))).toFixed(2)) < 0" color="red">mdi-currency-usd-off</v-icon>
            <v-icon v-else color="green" >mdi-currency-usd</v-icon>
          </div>
        </v-col>
      </template>
      <template v-slot:[`preco`]="{ item }">
        {{ formatCurrency(item.preco) }}
      </template>
      <template v-slot:[`item.inicio_vigencia`]="{ item }">
        {{ formatDate(item.inicio_vigencia) }}
      </template>
      <template v-slot:[`item.fim_vigencia`]="{ item }">
        {{ formatDate(item.fim_vigencia) }}
      </template>
      <template v-slot:item.data-table-select="{ item, isSelected, select }">
        <v-tooltip top>
          <template v-slot:activator="{ on, attrs }">
            <v-simple-checkbox :value="isSelected" @click="() => select(!isSelected)" v-on="on" v-bind="attrs">
              {{ isSelected }}
            </v-simple-checkbox>
          </template>
          <span>Selecione essa linha para REAJUSTAR o equipamento</span>
        </v-tooltip>
      </template>
    </v-data-table>
    <v-dialog v-if="dialog.create" v-model="dialog.create" max-width="700">
      <v-card>
        <v-card-title class="blod_color">
          Novo equipamento
          <v-spacer></v-spacer>
          <v-btn icon @click="dialog.create = false">
            <v-icon>
              mdi-close
            </v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text>
          <ProcedureFormEquipamento :loading="isLoading('post:procedures')" :onSubmit="postProcedures" :currentItem="newItem" />
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-dialog v-if="dialog.update" v-model="dialog.update" max-width="700">
      <v-card>
        <v-card-title class="blod_color">
          Editar Equipamento
          <v-spacer></v-spacer>
          <v-btn icon @click="dialog.update = false">
            <v-icon>
              mdi-close
            </v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text>
          <ProcedureFormEditEquipamento :loading="isLoading('put:procedures')" :onSubmit="putProcedures" :currentItem="selectedRow" />
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-dialog v-if="dialog.duplicate" v-model="dialog.duplicate" max-width="650">
      <v-card>
        <v-card-title class="blod_color">
          Reajustar equipamento
          <v-spacer></v-spacer>
          <v-btn icon @click="dialog.duplicate = false">
            <v-icon>
              mdi-close
            </v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text>
          <v-form ref="formRef">
            <v-row dense>
              <v-col cols="5">
                <v-text-field type="date" outlined dense v-model="inicio_vigencia" :rules="requiredField"
                  @change="forceRevalidate">
                  <template v-slot:label>
                    <span>Início da vigência <span style="color: red;">*</span></span>
                  </template>
                </v-text-field>
              </v-col>
              <v-col cols="5">
                <v-text-field hide-details outlined dense v-model.lazy="duplicatePercent" v-money="currencyMask">
                  <template v-slot:label>
                    <span>Percentual do Reajuste <span style="color: red;">*</span></span>
                  </template>
                </v-text-field>
              </v-col>
            </v-row>
            <v-row dense>
              <v-col class="text-start">
                <v-btn-toggle v-model="cloneProcedureStatus" mandatory class="white--text">
                  <v-btn :color="!cloneProcedureStatus ? 'error' : ''">
                    <v-tooltip top>
                      <template v-slot:activator="{ on }">
                        <span v-on="on">Inativo</span>
                      </template>
                      <span>Deixe INATIVO se HOJE não for a data de início do reajuste.</span>
                    </v-tooltip>
                  </v-btn>
                  <v-btn :color="cloneProcedureStatus ? 'green' : ''">
                    <v-tooltip top>
                      <template v-slot:activator="{ on }">
                        <span v-on="on">Ativo</span>
                      </template>
                      <span>Deixe ATIVO se HOJE for a data de início do reajuste.</span>
                    </v-tooltip>
                  </v-btn>
                </v-btn-toggle>
              </v-col>
              <v-col class="text-end">
                <v-tooltip top :disabled="isFormValid">
                  <template v-slot:activator="{ on, attrs }">
                    <span v-on="on" v-bind="attrs">
                      <ConfirmButton :onConfirm="duplicateProcedures" :color="isFormValid ? 'success' : 'grey'"
                        :loading="isSubmitting" :disabled="!isFormValid || isSubmitting">Salvar</ConfirmButton>
                    </span>
                  </template>
                  <span>Preencha todos os campos obrigatórios (*) para habilitar o botão.</span>
                </v-tooltip>
              </v-col>
            </v-row>
            <v-data-table :headers="headersDuplicate" :items="selectedRows" hide-default-footer>
              <template v-slot:[`item.preco`]="{ item }">
                {{ formatCurrency(item.preco) }}
              </template>
              <template v-slot:[`item.price_after`]="{ item }">
                {{ formatCurrency(Number(item.preco) + (item.preco * duplicatePercentNumber) / 100) }}
              </template>
              <template v-slot:[`item.rentabilidade`]="{item}">
                <v-col cols='1'>
                  <div class="d-flex justify-center align-center">
                    <v-icon v-if="(((Number(item.preco) + (item.preco * duplicatePercentNumber) / 100)).toFixed(2) - (item.equipamento.custo * (1 +((empresa[0].custo_fixo + empresa[0].imposto + empresa[0].rentabilidade) / 100))).toFixed(2)) < 0" color="red" >mdi-currency-usd-off</v-icon>
                    <v-icon v-else color="green" >mdi-currency-usd</v-icon>
                  </div>
                </v-col>
              </template>
            </v-data-table>
          </v-form>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import api from '../../../../http';
import UtilsFunc from '../../../../service/utilsFunc';
import ConfirmButton from '../../../ConfirmButton.vue';
import ProcedureFormEquipamento from './ProcedureFormEquipamento.vue';
import ProcedureFormEditEquipamento from './ProcedureFormEditEquipamento.vue';
// import moment from 'moment';
import './../../../../assets/css/styles.css';

const { withCRUDUtils } = UtilsFunc

export default {
  name: "ProceduresTable",
  props: {
    currentItem: Object,
    onSubmit: Function
  },
  data: () => withCRUDUtils({
    newItem: { ativo: 1 },
    defaultSort: {
      isDesc: true,
      name: "ativo",
    },
    dialog: {
      duplicate: false
    },
    requiredField: [e => e !== undefined || 'Obrigatório'],
    selectedRows: [],
    procedures: [],
    cloneProcedureStatus: false,
    duplicatePercent: '',
    isSubmitting: false,
    inicio_vigencia: '',
    empresa: [],
    equipamentos:[],
    currencyMask: {
      decimal: ',',
      thousands: '.',
      suffix: ' %',
      precision: 2,
    },
    headers: [
      { text: "Editar Equipamento", align: "center", value: "edit", width: 50, sortable: false },
      { text: "Equipamento", align: "center", value: "equipamento.nome" },
      { text: "Nome na Contratante", align: "center", value: "nomecomercial" },
      { text: "Cód. do equipamento", align: "center", value: "codigo" },
      { text: "Cód. da tabela", align: "center", value: "tabela.tabela" },
      { text: "Cód. da despesa", align: "center", value: "despesa.despesa" },
      { text: "Unid. Medida", align: "center", value: "unidade_medida" },
      { text: "Tipo de guia   ", align: "center", value: "tipoguia.nome" },
      { text: "Equipamento no XML", align: "center", value: "xmlagrupado.nome" },
      { text: "Preço", align: "center", value: "preco" },
      { text: "Rentabilidade", align: "center", value: "custoEstimado" },
      { text: "Início da vigência", align: "center", value: "inicio_vigencia" },
      { text: "Fim da vigência", align: "center", value: "fim_vigencia" },
      { text: "Status", align: "center", value: "ativo" },
    ],
    headersDuplicate: [
      { text: "Equipamento", value: "equipamento.nome" },
      { text: "Preço pré reajuste", value: "preco" },
      { text: "Preço pós reajuste", value: "price_after" },
      { text: "Rentabilidade", align: "center", value: "rentabilidade" },
    ],
  }),
  methods: {
    async getEquipamento() {
      const { data } = await api.get("equipamentos/nomeequipamentos/");
      this.equipamento = data;
      // console.log('equipamento -> ',this.equipamento)
    },
    async getOperatorProcedures() {
      const LOADING_NAME = 'get:procedures'
      this.setLoading(LOADING_NAME)
      try {
        const { data } = await api.get(`/operadoras/operadora-equipamentos/?operadora=${this.currentItem.id}`)
        // console.log('getOperatorProcedures -->',data)
        this.tableData = data
      } catch (error) {
        this.$errorHandler(error)
        this.$toast.error(`Desculpe! Aconteceu algo errado. Deu erro ${error}`)
      } finally {
        this.setLoading(LOADING_NAME, true)
      }
    },
    async getModalities() {
      const LOADING_NAME = "get:procedures";
      this.setLoading(LOADING_NAME);
      try {
        const { data } = await api.get("/uteis/modalidades/");
        this.tableData = data;
      }
      catch (error) {
        this.$errorHandler(error);
        this.$toast.error(`Desculpe! Aconteceu algo errado. Deu erro ${error}`)
      }
      finally {
        this.setLoading(LOADING_NAME, true);
      }
    },
    async postProcedures(fields) {
      const LOADING_NAME = 'post:procedures'
      this.setLoading(LOADING_NAME)
      const fieldsWithOperator = {
        ...fields,
        operadora: this.currentItem.id
      }
      try {
        await api.post('/operadoras/operadora-equipamentos/', fieldsWithOperator)
        await this.getOperatorProcedures()
        this.$toast.success('Equipamento inserido com sucesso!')
        this.dialog.create = false
      } catch (error) {
        this.$errorHandler(error)
        this.$toast.error(`Desculpe! Aconteceu algo errado. Deu erro ${error}`)
      } finally {
        this.setLoading(LOADING_NAME, true)
      }
    },
    async putProcedures(fields) {
      const LOADING_NAME = 'put:procedures'
      this.setLoading(LOADING_NAME)

      try {
        this.isSubmitting = true;
        await api.put(`/operadoras/operadora-equipamento/${fields.id}/`, fields)
        await this.getOperatorProcedures()
        this.$toast.success('Equipamento atualizado com sucesso!')
        this.dialog.update = false
      } catch (error) {
        // this.$errorHandler(error)
        this.$toast.error(`Desculpe! Aconteceu algo errado. Deu erro ${error}`)
      } finally {
        this.setLoading(LOADING_NAME, true)
        this.isSubmitting = false;
      }
    },
    async duplicateProcedures() {
      const isValid = this.$refs.formRef.validate();
      if (!isValid) {
        this.$toast.error("Por favor, preencha todos os campos obrigatórios.");
        return;
      }
      const formatOperator = (operator) => ({
        ...operator,
        operadora: operator.operadora.id,
        equipamento: operator.equipamento.id,
        tabela: operator.tabela?.id,
        despesa: operator.despesa?.id,
        tipoguia: operator.tipoguia?.id,
        xmlagrupado: operator.xmlagrupado?.id
      })

      const proceduresToDuplicate = this.selectedRows.map(row => {
        const priceAfterReajuste = (Number(row.preco) + (row.preco * this.duplicatePercentNumber) / 100).toFixed(2);
        // console.log(Boolean(this.cloneProcedureStatus))
        return api.post('/operadoras/operadora-equipamentos/', { ...formatOperator(row), preco: priceAfterReajuste, inicio_vigencia: this.inicio_vigencia, ativo: Boolean(this.cloneProcedureStatus) });
      });
      // const proceduresToUpdate = this.selectedRows.map(row => api.put(`/operadoras/operadora-procedimento/${row.id}/`, { ...formatOperator(row)}))
      await Promise.all(proceduresToDuplicate)
      await this.getOperatorProcedures()
      this.$toast.success('OK')
      this.selectedRows = []
      this.inicio_vigencia = ""
      this.dialog.duplicate = false
    },
    async getEmpresa() {
      const LOADING_NAME = 'get:tableValues'
      this.setLoading(LOADING_NAME)
      try {
        const { data } = await api.get('/multi_empresas/empresas/')
        this.empresa = data.filter(item => item.filial == 2)
        // console.log(this.empresa)
      } catch (error) {
        this.$errorHandler(error)
      } finally {
        this.setLoading(LOADING_NAME, true)
      }
    },
    formatCurrency(value) {
      return new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(value);
    },
    formatDate(dateString) {
      if (!dateString) return '';
      const date = new Date(dateString + 'T00:00:00'); // Ajusta a data para ser tratada como fuso horário local
      return date.toLocaleDateString('pt-BR');
    },
    forceRevalidate() {
      this.$nextTick(() => {
        if (this.$refs.formRef) {
          this.$refs.formRef.validate();
        }
      });
    },
    validateForm() {
      if (this.$refs.formRef) {
        this.$refs.formRef.validate();
        this.$emit('form-validated');
      }
    },
  },
  mounted() {
    this.getEquipamento();
    this.getOperatorProcedures();
    this.getEmpresa();
    },
  components: { ProcedureFormEquipamento, ConfirmButton, ProcedureFormEditEquipamento },
  computed: {
    duplicatePercentNumber() {
      if (typeof this.duplicatePercent === 'string') {
        return Number(this.duplicatePercent.replace(/%|\.|\s/g, '').replace(',', '.'));
      }
      return 0;
    },
    formattedData() {
      const formatDate = Intl.DateTimeFormat('pt-br', { dateStyle: 'short' }).format
      return this.tableData.map(row => ({ ...row, formatedCreatedAt: formatDate(new Date(row.created_at)) }))
    },
    isFormValid() {
      return !!(this.duplicatePercentNumber > 0 && this.inicio_vigencia);
    },
    requiredAsterisk() {
      return '<span style="color: red;">*</span>';
    },  

  },
  watch: {
    'selectedRows.fim_vigencia'() {
      this.$refs.formRef.validate(); // Revalida o formulário
    },
    // 'inicio_vigencia'() {
    //   this.$refs.formRef.validate(); // Revalida o formulário

    //   const isToday = moment().startOf('d').isSame(moment(this.inicio_vigencia))
    //   if (isToday) {
    //     this.cloneProcedureStatus = 1
    //   } else {
    //     this.cloneProcedureStatus = 0
    //   }
    // },
    'inicio_vigencia'(newValue) {
      const today = new Date().toISOString().split('T')[0];
      this.cloneProcedureStatus = newValue === today ? 1 : 0;
    },
  },
}
</script>

<style scoped>

</style>
