<template>
  <div>
    <v-data-table :loading="isLoading('get:plans')" :items="tableData" :search="search" :headers="headers">
      <template v-slot:top>
        <v-row class="mt-2">
          <v-col cols="12">
            <v-row>
              <v-col cols="5">
                <v-text-field dense outlined v-model="search" label="Pesquisar" class="mx-0" append-icon="mdi-magnify" clearable  />
              </v-col>
              <v-col class="text-end">
                <v-btn class="mr-4 elevation-0" text @click="getPlans">
                  <v-icon left>
                    mdi-reload
                  </v-icon>
                  atualizar
                </v-btn>
                <v-btn color="success" class="elevation-0" @click="dialog.create = true">
                  <v-icon left>
                    mdi-plus
                  </v-icon>
                  Novo
                </v-btn>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </template>
      <template v-slot:[`item.ativo`]="{ item }">
        <v-chip v-if="item.ativo === true" color="blue" class="white--text">ATIVA</v-chip>
        <v-chip v-else>INATIVA</v-chip>
      </template>
      <template v-slot:[`item.edit`]="{ item }">
            <v-tooltip top>
                <template v-slot:activator="{ on, attrs }">
                    <v-btn icon v-bind="attrs" v-on="on" @click="onSelectRow(item, 'update')">
                        <v-icon color="green">mdi-pencil</v-icon>
                    </v-btn>
                </template>
                <span>Clique para editar</span>
            </v-tooltip>
        </template>
    </v-data-table>
    <v-dialog v-if="dialog.create" v-model="dialog.create" max-width="650">
      <v-card>
        <v-card-title class="blod_color">
          Novo Plano
          <v-spacer></v-spacer>
          <v-btn icon @click="dialog.create = false">
            <v-icon>
              mdi-close
            </v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text>
          <BaseFormPlans :currentItem="newItem" :loading="isLoading('post:plans')" :onSubmit="handleCreatePlan" />
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-dialog v-if="dialog.update" v-model="dialog.update" max-width="650">
      <v-card>
        <v-card-title class="blod_color">
          Editar Plano
          <v-spacer></v-spacer>
          <v-btn icon @click="dialog.update = false">
            <v-icon>
              mdi-close
            </v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text>
          <BaseFormPlans :currentItem="selectedRow" :loading="isLoading('put:plans')" :onSubmit="handleUpdatePlan" />
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import api from '../../../../http';
import UtilsFunc from '../../../../service/utilsFunc';
import BaseFormPlans from './BaseFormPlans.vue';
import './../../../../assets/css/styles.css';

const { withCRUDUtils } = UtilsFunc

export default {
  name: "PlansTable",
  props: {
    currentItem: Object
  },
  components: {
    BaseFormPlans
  },
  data: () => withCRUDUtils({
    newItem: {
      ativo: 1
    },
    requiredField: [UtilsFunc.isEmptyField('Obrigatório')],
    headers: [
      { text: "Editar plano", value: "edit", sortable: false, width: 120, align:"center" },
      { text: "Nome do plano", value: "nome", align:"center" },
      { text: "Status", value: "ativo", align:"center" },
    ]
  }),
  methods: {
    async getPlans() {
      const LOADING_NAME = 'get:plans'
      this.setLoading(LOADING_NAME)
      try {
        const { data } = await api.get(`/operadoras/planos/?operadora=${this.currentItem.id}`)
        this.tableData = data
      } catch (error) {
        this.$errorHandler(error)
        this.$toast.error(`Desculpe! Aconteceu algo errado. Deu erro ${error}`)
      } finally {
        this.setLoading(LOADING_NAME, true)
      }
    },
    async handleCreatePlan(fields) {
      const LOADING_NAME = 'post:plans'
      this.setLoading(LOADING_NAME)
      try {
        await api.post("/operadoras/planos/", { ...fields, operadora: this.currentItem.id })
        await this.getPlans()
        this.dialog.create = false
        this.$toast.success("Plano Inserido com Sucesso!")
      } catch (error) {
        this.$errorHandler(error)
        this.$toast.error(`Desculpe! Aconteceu algo errado. Deu erro ${error}`)
      } finally {
        this.setLoading(LOADING_NAME, true)
      }
    },
    async handleUpdatePlan(fields) {
      const LOADING_NAME = 'put:plans'
      this.setLoading(LOADING_NAME)
      try {
        await api.put(`/operadoras/plano/${fields.id}/`, fields)
        await this.getPlans()
        this.dialog.update = false
        this.$toast.success("Plano Atualizado com Sucesso!")
      } catch (error) {
        this.$errorHandler(error)
        this.$toast.error(`Desculpe! Aconteceu algo errado. Deu erro ${error}`)
      } finally {
        this.setLoading(LOADING_NAME, true)
      }
    }
  },
  mounted() {
    this.getPlans()
  }
}
</script>
