<template>
  <div>
    <v-data-table :loading="isLoading('get:pacote')" :headers="headers" :items="pacote" :server-items-length="-1"
      class="mb-4" :sort-by.sync="defaultSort.name" :sort-desc.sync="defaultSort.isDesc" v-model="selectedRows"
      show-select :search="search" :single-expand="singleExpand" :expanded.sync="expanded" show-expand>
      <template v-slot:top>
        <v-row class="mt-2">
          <v-col cols="12">
            <v-row>
              <v-col cols="5">
                <v-text-field dense outlined v-model="search" label="Pesquisar" class="mx-0" append-icon="mdi-magnify"
                  clearable />
              </v-col>
              <v-col class="text-end">
                <v-btn class="mr-4 elevation-0" text @click="getPacote">
                  <v-icon left>
                    mdi-reload
                  </v-icon>
                  atualizar
                </v-btn>
                <v-btn class="mr-4 elevation-0" text @click="collapseAll"> Recolher Linhas </v-btn>
                <v-btn color="info" class="elevation-0 mr-4" :disabled="selectedRows.length < 1"
                  @click="dialog.duplicate = true">
                  <v-icon left>
                    mdi-content-copy
                  </v-icon>
                  Reajuste
                </v-btn>
                <v-btn color="success" class="elevation-0" @click="dialog.create = true">
                  <v-icon left>
                    mdi-plus
                  </v-icon>
                  Novo
                </v-btn>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </template>
      <template v-slot:[`item.ativo`]="{ item }">
        <v-chip v-if="item.ativo === true" color="blue" class="white--text">ATIVA</v-chip>
        <v-chip v-else>INATIVA</v-chip>
      </template>
      <!-- Caneta para Editar linha pacote -->
      <template v-slot:[`item.edit`]="{ item }">
        <v-tooltip top>
          <template v-slot:activator="{ on, attrs }">
            <v-btn icon v-bind="attrs" v-on="on" @click="onSelectRow(item, 'update')">
              <v-icon color="green">mdi-pencil</v-icon>
            </v-btn>
          </template>
          <span>Clique para editar</span>
        </v-tooltip>
      </template>
      <template v-slot:expanded-item="{ headers, item }">
        <td :colspan="headers.length" class="py-4 grey lighten-2  text-start">
          <PacoteExpand :pacoteId="item.id" :currentItem="currentItem"></PacoteExpand>
        </td>
      </template>
      <template v-slot:[`item.custoEstimado`]="{ item }">
        <v-col cols='1'>
          <div class="d-flex justify-center align-cente">
            <v-icon
              v-if="item.equipamento && empresa[0] && (item.preco - (item.equipamento.custo * (1 + ((empresa[0].custo_fixo + empresa[0].imposto + empresa[0].rentabilidade) / 100))).toFixed(2)) < 0"
              color="red">mdi-currency-usd-off</v-icon>
            <v-icon v-else color="green">mdi-currency-usd</v-icon>
          </div>
        </v-col>
      </template>
      <template v-slot:[`item.preco`]="{ item }">
        {{ formatCurrency(item.preco) }}
      </template>
      <template v-slot:[`item.inicio_vigencia`]="{ item }">
        {{ formatDate(item.inicio_vigencia) }}
      </template>
      <template v-slot:[`item.fim_vigencia`]="{ item }">
        {{ formatDate(item.fim_vigencia) }}
      </template>
      <template v-slot:[`item.data-table-select`]="{ item, isSelected, select }">
        <v-tooltip top>
          <template v-slot:activator="{ on, attrs }">
            <v-simple-checkbox :value="isSelected" @click="() => select(item, !isSelected)" v-on="on" v-bind="attrs">
              {{ isSelected }}
            </v-simple-checkbox>
          </template>
          <span>Selecione essa linha para REAJUSTAR o pacote</span>
        </v-tooltip>
      </template>
    </v-data-table>
    <!-- Botão para Criara novo Pacote -->
    <v-dialog v-if="dialog.create" v-model="dialog.create" max-width="700">
      <v-card>
        <v-card-title class="blod_color">
          Novo Pacote
          <v-spacer></v-spacer>
          <v-btn icon @click="dialog.create = false">
            <v-icon>
              mdi-close
            </v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text>
          <ProcedureFormPacote :loading="isLoading('post:procedures')" :onSubmit="postProcedures"
            :currentItem="newItem" />
        </v-card-text>
      </v-card>
    </v-dialog>
    <!-- Pagina de Edição de Pacote -->
    <v-dialog v-if="dialog.update" v-model="dialog.update" max-width="700">
      <v-card>
        <v-card-title class="blod_color">
          Editar Pacote: {{ selectedRow.nome }}
          <v-spacer></v-spacer>
          <v-btn icon @click="dialog.update = false">
            <v-icon>
              mdi-close
            </v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text>
          <ProcedureFormEditPacote :loading="isLoading('put:pacote')" :onSubmit="putPacote" :currentItem="selectedRow"
            :pacotes="pacote" :idoperadora="currentItem.id" />
        </v-card-text>
      </v-card>
    </v-dialog>
    <!-- Parte de Reajuste -->
    <v-dialog v-if="dialog.duplicate" v-model="dialog.duplicate" max-width="650">
      <v-card>
        <v-card-title class="blod_color">
          Reajustar pacote
          <v-spacer></v-spacer>
          <v-btn icon @click="dialog.duplicate = false">
            <v-icon>
              mdi-close
            </v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text>
          <v-form ref="formRef">
            <v-row dense>
              <v-col cols="5">
                <v-text-field type="date" outlined dense v-model="inicio_vigencia" :rules="requiredField"
                  @change="forceRevalidate">
                  <template v-slot:label>
                    <span>Início da vigência <span style="color: red;">*</span></span>
                  </template>
                </v-text-field>
              </v-col>
              <v-col cols="5">
                <v-text-field hide-details outlined dense v-model.lazy="duplicatePercent" v-money="currencyMask">
                  <template v-slot:label>
                    <span>Percentual do Reajuste <span style="color: red;">*</span></span>
                  </template>
                </v-text-field>
              </v-col>
            </v-row>
            <v-row dense>
              <v-col class="text-start">
                <v-btn-toggle v-model="cloneProcedureStatus" mandatory class="white--text">
                  <v-btn :color="!cloneProcedureStatus ? 'error' : ''">
                    <v-tooltip top>
                      <template v-slot:activator="{ on }">
                        <span v-on="on">Inativo</span>
                      </template>
                      <span>Deixe INATIVO se HOJE não for a data de início do reajuste.</span>
                    </v-tooltip>
                  </v-btn>
                  <v-btn :color="cloneProcedureStatus ? 'green' : ''">
                    <v-tooltip top>
                      <template v-slot:activator="{ on }">
                        <span v-on="on">Ativo</span>
                      </template>
                      <span>Deixe ATIVO se HOJE for a data de início do reajuste.</span>
                    </v-tooltip>
                  </v-btn>
                </v-btn-toggle>
              </v-col>
              <v-col class="text-end">
                <v-tooltip top :disabled="isFormValid">
                  <template v-slot:activator="{ on, attrs }">
                    <span v-on="on" v-bind="attrs">
                      <ConfirmButton :onConfirm="duplicatePacote" :color="isFormValid ? 'success' : 'grey'"
                        :loading="isSubmitting" :disabled="!isFormValid || isSubmitting">Salvar</ConfirmButton>
                    </span>
                  </template>
                  <span>Preencha todos os campos obrigatórios (*) para habilitar o botão.</span>
                </v-tooltip>
              </v-col>
            </v-row>
            <v-data-table :headers="headersDuplicatePacote" :items="selectedRows" hide-default-footer>
              <template v-slot:[`item.preco`]="{ item }">
                {{ formatCurrency(item.preco) }}
              </template>
              <template v-slot:[`item.price_after`]="{ item }">
                {{ formatCurrency(Number(item.preco) + (item.preco * duplicatePercentNumber) / 100) }}
              </template>
              <!-- <template v-slot:[`item.rentabilidade`]="{item}">
                <v-col cols='1'>
                  <div class="d-flex justify-center align-center">
                    <v-icon v-if="(((Number(item.preco) + (item.preco * duplicatePercentNumber) / 100)).toFixed(2) - (item.equipamento.custo * (1 +((empresa[0].custo_fixo + empresa[0].imposto + empresa[0].rentabilidade) / 100))).toFixed(2)) < 0" color="red" >mdi-currency-usd-off</v-icon>
                    <v-icon v-else color="green" >mdi-currency-usd</v-icon>
                  </div>
                </v-col>
              </template> -->
            </v-data-table>
          </v-form>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import api from '../../../../http';
import UtilsFunc from '../../../../service/utilsFunc';
import ProcedureFormEditPacote from './ProcedureFormEditPacote.vue';
import ProcedureFormPacote from './ProcedureFormPacote.vue';
import './../../../../assets/css/styles.css';
import PacoteExpand from './PacoteExpand.vue';
import ConfirmButton from '../../../ConfirmButton.vue';

const { withCRUDUtils } = UtilsFunc

export default {
  name: "ProceduresTable",
  components: { ProcedureFormEditPacote, ProcedureFormPacote, PacoteExpand, ConfirmButton },
  props: {
    currentItem: Object,
    onSubmit: Function
  },
  data: () => withCRUDUtils({
    newItem: { ativo: 1 },
    defaultSort: {
      isDesc: true,
      name: "ativo",
    },
    dialog: {
      duplicate: false
    },
    requiredField: [e => e !== undefined || 'Obrigatório'],
    selectedRows: [],
    procedures: [],
    cloneProcedureStatus: false,
    duplicatePercent: '',
    isSubmitting: false,
    inicio_vigencia: '',
    empresa: [],
    pacote: [],
    expanded: [],
    singleExpand: false,
    currencyMask: {
      decimal: ',',
      thousands: '.',
      suffix: ' %',
      precision: 2,
    },
    headers: [
      { text: "Editar Pacote", align: "center", value: "edit", width: 50, sortable: false },
      { text: "Id", align: "center", value: "id" },
      { text: "Pacote", align: "center", value: "nome" },
      { text: "Nome Comercial", align: "center", value: "nomecomercial" },
      { text: "Modalidade", align: "center", value: "modalidade.nome" },
      { text: "Código", align: "center", value: "codigo" },
      { text: "Cód. da tabela", align: "center", value: "tabela.tabela" },
      { text: "Cód. da despesa", align: "center", value: "despesa.despesa" },
      { text: "Unid. Medida", align: "center", value: "unidade_medida.codigo" },
      { text: "Tipo de guia   ", align: "center", value: "tipoguia.nome" },
      { text: "Pacote no XML", align: "center", value: "xmlagrupado.nome" },
      { text: "Preço", align: "center", value: "preco" },
      // { text: "Rentabilidade", align: "center", value: "custoEstimado" },
      { text: "Início da vigência", align: "center", value: "inicio_vigencia" },
      { text: "Fim da vigência", align: "center", value: "fim_vigencia" },
      { text: "Status", align: "center", value: "ativo" },
    ],
    headersDuplicatePacote: [
      { text: "Pacote", value: "nome" },
      { text: "Preço pré reajuste", value: "preco" },
      { text: "Preço pós reajuste", value: "price_after" },
      // { text: "Rentabilidade", align: "center", value: "rentabilidade" },
    ],
  }),
  methods: {
    async getPacote() {
      const LOADING_NAME = 'get:pacote'
      this.setLoading(LOADING_NAME)
      try {
        const { data } = await api.get(`/operadoras/operadora-pacotes/?operadora=${this.currentItem.id}`)
        this.pacote = data.sort((a, b) => {
          if (a.ativo === b.ativo) {
            return a.nome.localeCompare(b.nome);
          }
          return a.ativo ? -1 : 1;
        });
        // console.log(this.pacote)
      } catch (error) {
        this.$errorHandler(error)
        this.$toast.error(`Desculpe! Aconteceu algo errado. Deu erro ${error}`)
      } finally {
        this.setLoading(LOADING_NAME, true)
      }
    },
    async getModalities() {
      const LOADING_NAME = "get:pacote";
      this.setLoading(LOADING_NAME);
      try {
        const { data } = await api.get("/uteis/modalidades/");
        this.ppacote = data;
      }
      catch (error) {
        this.$errorHandler(error);
        this.$toast.error(`Desculpe! Aconteceu algo errado. Deu erro ${error}`)
      }
      finally {
        this.setLoading(LOADING_NAME, true);
      }
    },
    async postProcedures(fields) {
      const LOADING_NAME = 'post:procedures'
      this.setLoading(LOADING_NAME)
      const fieldsWithOperator = {
        ...fields,
        operadora: this.currentItem.id
      }
      try {
        await api.post('/operadoras/operadora-pacotes/', fieldsWithOperator)
        await this.getPacote()
        this.$toast.success('Taxa criada com sucesso!')
        this.dialog.create = false
      } catch (error) {
        this.$toast.error(`Desculpe! Aconteceu algo errado. Deu erro ${error}`)
      } finally {
        this.setLoading(LOADING_NAME, true)
      }
    },
    async putPacote(fields) {
      const LOADING_NAME = 'put:pacote'
      this.setLoading(LOADING_NAME)

      try {
        this.isSubmitting = true;
        // console.log('Put Pacote -> ',fields)
        await api.put(`/operadoras/operadora-pacote/${fields.id}/`, fields)
        await this.getPacote()
        this.$toast.success('Pacote atualizado com sucesso!')
        this.dialog.update = false
      } catch (error) {
        this.$toast.error(`Desculpe! Aconteceu algo errado. Deu erro ${error}`)
      } finally {
        this.setLoading(LOADING_NAME, true)
        this.isSubmitting = false;
      }
    },
    async putItemPacote(fields) {
      const LOADING_NAME = 'put:itemPacote'
      this.setLoading(LOADING_NAME)

      try {
        this.isSubmitting = true;
        // console.log(fields)
        await api.put(`/operadoras/operadora-itempacote/${fields.id}/`, fields)
        await this.getPacote()
        this.$toast.success('Itens do Pacote atualizados com sucesso!')
        this.dialog.updateItem = false
      } catch (error) {
        this.$toast.error(`Desculpe! Aconteceu algo errado. Deu erro ${error}`)
      } finally {
        this.setLoading(LOADING_NAME, true)
        this.isSubmitting = false;
      }
    },
    async duplicatePacote() {
      const isValid = this.$refs.formRef.validate();
      if (!isValid) {
        this.$toast.error("Por favor, preencha todos os campos obrigatórios.");
        return;
      }
      const formatOperator = (operator) => ({
        ...operator,
        operadora: operator?.operadora.id,
        tabela: operator?.tabela?.id,
        despesa: operator?.despesa?.id,
        tipoguia: operator?.tipoguia?.id,
        equipamento: operator?.equipamento?.id,
        xmlagrupado: operator?.xmlagrupado?.id,
        modalidade: operator?.modalidade?.id,
        unidade_medida: operator?.unidade_medida?.id
      })

      const formatItem = (item) => ({
        ...item,
        taxa: item?.taxa?.id,
        equipamento: item?.equipamento?.id,
        procedimento: item?.procedimento?.id,
      })

      const proceduresToDuplicate = this.selectedRows.map(async (row) => {
        const priceAfterReajuste = (Number(row.preco) + (row.preco * this.duplicatePercentNumber) / 100).toFixed(2);
        const body = { ...formatOperator(row), preco: priceAfterReajuste, inicio_vigencia: this.inicio_vigencia, ativo: Boolean(this.cloneProcedureStatus) }

        const { data: items } = await api.get(`/operadoras/operadora-itempacotes/?operadora=${body.operadora}&pacote=${body.id}`);
        const { data } = await api.post('/operadoras/operadora-pacotes/', body);

        const activeItems = items
          .filter(item => item.ativo)
          .map(item => formatItem({ ...item, inicio_vigencia: this.inicio_vigencia, pacote: data.id }))

        await Promise.all(
          activeItems.map(item => api.post('/operadoras/operadora-itempacotes/', item))
        )

        return row
      });

      await Promise.all(proceduresToDuplicate)
      await this.getPacote()
      this.$toast.success('Pacote Criado e Reajusta com Sucesso!')
      this.selectedRows = []
      this.inicio_vigencia = ""
      this.dialog.duplicate = false
    },
    async getEmpresa() {
      const LOADING_NAME = 'get:tableValues'
      this.setLoading(LOADING_NAME)
      try {
        const { data } = await api.get('/multi_empresas/empresas/')
        this.empresa = data.filter(item => item.filial == 2)
        // console.log(this.empresa)
      } catch (error) {
        this.$errorHandler(error)
      } finally {
        this.setLoading(LOADING_NAME, true)
      }
    },
    formatCurrency(value) {
      return new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(value);
    },
    formatDate(dateString) {
      if (!dateString) return '';
      const date = new Date(dateString + 'T00:00:00'); // Ajusta a data para ser tratada como fuso horário local
      return date.toLocaleDateString('pt-BR');
    },
    forceRevalidate() {
      this.$nextTick(() => {
        if (this.$refs.formRef) {
          this.$refs.formRef.validate();
        }
      });
    },
    validateForm() {
      if (this.$refs.formRef) {
        this.$refs.formRef.validate();
        this.$emit('form-validated');
      }
    },
    collapseAll() {
      this.expanded = [];
    },
  },
  mounted() {
    this.getPacote();
    this.getEmpresa();
  },
  computed: {
    duplicatePercentNumber() {
      if (typeof this.duplicatePercent === 'string') {
        return Number(this.duplicatePercent.replace(/%|\.|\s/g, '').replace(',', '.'));
      }
      return 0;
    },
    isFormValid() {
      return !!(this.duplicatePercentNumber > 0 && this.inicio_vigencia);
    },
    requiredAsterisk() {
      return '<span style="color: red;">*</span>';
    },

  },
  watch: {
    'selectedRows.fim_vigencia'() {
      this.$refs.formRef.validate();
    },
    'inicio_vigencia'(newValue) {
      const today = new Date().toISOString().split('T')[0];
      this.cloneProcedureStatus = newValue === today ? 1 : 0;
    },
  },
}
</script>

<style scoped></style>
