<template>
  <div>
    <v-row dense>
      <v-col cols="2">
        <v-select v-model="fields.ilimitado" :items="statusOptions" item-value="value" item-text="nome" outlined>
          <template v-slot:label>
            <span>Iliminado <span style="color: red;">*</span></span>
          </template></v-select>
      </v-col>
      <v-col cols="3">
        <v-text-field label="Quantidade" v-model="fields.quantidade" type="number" outlined></v-text-field>
      </v-col>
      <v-col>
        <v-text-field v-model="fields.inicio_vigencia" outlined type="date">
          <template v-slot:label>
            <span>Inicio de Vigência <span style="color: red;">*</span></span>
          </template>
        </v-text-field>
      </v-col>
      <v-col>
        <v-text-field label="Fim de Vigência" v-model="fields.fim_vigencia" outlined type="date"></v-text-field>
      </v-col>
    </v-row>
    <!-- Botão Ativo / Inativo / Salvar-->
    <v-row dense>
      <v-col>
        <label>Status</label>
        <div class="d-flex">
          <v-btn-toggle v-model="fields.ativo" mandatory class="white--text">
            <v-btn :color="!fields.ativo ? 'error' : ''">
              Inativo
            </v-btn>
            <v-btn :color="fields.ativo ? 'green' : ''">
              Ativo
            </v-btn>
          </v-btn-toggle>
        </div>
      </v-col>
      <v-col class="text-end mt-6">
        <v-tooltip top :disabled="isFormValid">
          <template v-slot:activator="{ on, attrs }">
            <span v-on="on" v-bind="attrs">
              <ConfirmButton :color="isFormValid ? 'success' : 'grey'" :loading="isSubmitting"
                :onConfirm="onPrepareSubmit" :disabled="!isFormValid || isSubmitting">Salvar</ConfirmButton>
            </span>
          </template>
          <span>Preencha todos os campos obrigatórios (*) para habilitar o botão.</span>
        </v-tooltip>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import UtilsFunc from '@/service/utilsFunc';
import ConfirmButton from '../../../ConfirmButton.vue';
// import api from '../../../../http';

export default {
  name: "PacoteFormEditItemPacoteIndividualizado",
  components: { ConfirmButton },
  props: {
    currentItem: {
      type: Object, default: () => {
      }
    },
    loading: Boolean,
    onSubmit: Function,
    idoperadora: Number,

  },
  data: () => UtilsFunc.withCRUDUtils({
    itempacote: [],
    updateItemDialog: false,
    isSubmitting: false,
    fields: {},
    requiredField: [e => (e && e !== "") || 'Obrigatório'],
    statusOptions: [
      { nome: "SIM", value: true },
      { nome: "NÃO", value: false },
    ],
  }),
  computed: {
    requiredAsterisk() {
      return '<span style="color: red;">*</span>';
    },
    isFormValid() {
      return !!(this.fields.ilimitado !== undefined &&
        this.fields.inicio_vigencia);
    },
  },
  methods: {
    async onPrepareSubmit() {
      this.isSubmitting = true;
      const fields = {
        ...this.fields,
        pacote: this.fields.pacote?.id ? this.fields.pacote?.id : this.fields.pacote,
        procedimento: this.fields.procedimento?.id ? this.fields.procedimento?.id : this.fields.procedimento,
        equipamento: this.fields.equipamento?.id ? this.fields.equipamento?.id : this.fields.equipamento,
        taxa: this.fields.taxa?.id ? this.fields.taxa?.id : this.fields.taxa,
        ativo: Boolean(this.fields.ativo)
      }
      try {
        // console.log(fields)
        await this.onSubmit(fields);
      } catch (error) {
        this.$toast.error(`Desculpe. Apresentou algum erro. ${error}`);
      } finally {
        this.isSubmitting = false;
      }
    this.isSubmitting = false;
    }
    
  },
  mounted() {
    this.fields = {
      ...this.currentItem,
      ativo: Number(this.currentItem.ativo)
    }
  }
}
</script>
