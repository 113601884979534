<template>
  <div>
    <v-form @submit.prevent="onLocalSubmit" ref="form">
      <v-row class="mt-2" dense>
        <v-col>
          <v-row dense>
            <v-col>
              <Input v-model="currentItem.nome" label="Nome do Plano" :rules="requiredField" clearable/>
            </v-col>
          </v-row>
          <v-row dense>
            <v-col>
              <label>
                Status
              </label>
              <div class="d-flex">
                <v-btn-toggle v-model="currentItem.ativo" mandatory class="white--text">
                  <v-btn :color="!currentItem.ativo ? 'error' : ''">
                    Inativo
                  </v-btn>
                  <v-btn :color="currentItem.ativo ? 'green' : ''">
                    Ativo
                  </v-btn>
                </v-btn-toggle>
              </div>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
      <v-row dense>
        <v-col class='text-end'>
          <ConfirmButton :loading="loading" color="success">
            Salvar
          </ConfirmButton>
        </v-col>
      </v-row>
    </v-form>
  </div>
</template>

<script>
import Input from '../../../Input.vue';
import UtilsFunc from '../../../../service/utilsFunc';
import ConfirmButton from '../../../ConfirmButton.vue';

const { withCRUDUtils } = UtilsFunc

export default {
  name: "BaseFormPlans",
  props: {
    currentItem: Object,
    onSubmit: Function,
    loading: Boolean,
  },
  data: () => withCRUDUtils({
    doctors: [],
    logoPreview: null,
    requiredField: [UtilsFunc.isEmptyField('Obrigatório')],
    filesToUpload: {}
  }),
  components: { Input, ConfirmButton },
  methods: {
    onLocalSubmit() {
      const isValidForm = this.$refs.form.validate();
      if (!isValidForm) {
        this.$toast.error("Por favor, preencha todos os campos obrigatórios.");
        return;
      }
      this.onSubmit({ ...this.currentItem, logo: this.filesToUpload.avatar })
    },
    onFileSelected(event, fieldName) {
      const currentItemField = "avatar"
      const previewName = "logoPreview"
      if (!event) {
        const defaultFile = this.currentItem[currentItemField];
        this[previewName] = defaultFile;
      } else {
        this.filesToUpload[fieldName] = event;
        this[previewName] = window.URL.createObjectURL(event);
      }
    },
  },
  mounted() {
    this.currentItem.ativo = Number(this.currentItem.ativo)
  },
}
</script>

<style scoped>
.main-fields {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(220px, 1fr));
  column-gap: 1rem;
}
</style>
